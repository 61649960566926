import { GameType } from "../services/games"
import { SUPPORTED_NETWORK } from "./network"
import { utils } from "ethers"

const sanitizeHtml = require("sanitize-html")

export function truncateWords(value, numOfWords, tail = "...") {
    if (!value) {
        return value
    }

    const content = value.split(" ").splice(0, numOfWords).join(" ")

    if (content.length < value.length) {
        return `${content}${tail}`
    }

    return content
}

export function truncateLength(value, length, tail = "...") {
    if (!value) {
        return value
    }

    if (value.length <= length) {
        return value
    }

    const content = value.substring(0, length)

    return `${content}${tail}`
}

export function tryGetErrorMessage(exception: any) {
    try {
        if (exception.response) {
            return exception.response.data.message
        }

        return exception.data.message.replace("execution reverted:", "")
    } catch (e) {
        return exception.message.replace("execution reverted:", "") ?? JSON.stringify(exception)
    }
}

export function shortenHex(hex: string, length = 4) {
    if (!hex) {
        return
    }

    return `${hex.substring(0, length + 2)}…${hex.substring(hex.length - length)}`
}

export function formatEtherscanLink(type: "Account" | "Transaction", data: [number, string]) {
    const [chainId, key] = data

    let scanUrl
    for (const networkName in SUPPORTED_NETWORK) {
        const network = SUPPORTED_NETWORK[networkName]
        if (chainId === parseInt(network.chainId)) {
            scanUrl = network.blockExplorerUrls[0]
        }
    }

    switch (type) {
        case "Account": {
            return `${scanUrl}/address/${key}`
        }
        case "Transaction": {
            return `${scanUrl}/tx/${key}`
        }
    }
}

export function getResultText(result, selections) {
    if (!selections || !selections.length) {
        return result === 1 ? "True" : "False"
    }

    return selections.find((x) => x.index === result)?.name
}

export function shorten(value, maxLength, separator = " ") {
    if (!value) {
        return value
    }

    if (value.length <= maxLength) {
        return value
    }

    return `${value.substring(0, value.lastIndexOf(separator, maxLength))}...`
}

export function formatTwitterLink(username) {
    return `https://twitter.com/${username}`
}

export function getTwitterName(link) {
    if (!link) {
        return ""
    }

    const splits = link.split("/")

    return splits[splits.length - 1]
}

export function getGameTypeString(type) {
    try {
        return GameType[type].toLowerCase()
    } catch (ex) {
        console.log(ex)

        return "prediction"
    }
}

export function getGameTypeFromString(gameStringType) {
    if (!gameStringType) {
        return null
    }

    let gameType
    switch (gameStringType.toLowerCase()) {
        case "insurance":
            gameType = GameType.INSURANCE
            break
        case "debate":
            gameType = GameType.DEBATE
            break
        case "predict":
            gameType = GameType.PREDICTION
            break
        case "prediction":
            gameType = GameType.PREDICTION
            break
    }

    return gameType
}

export function formatBreakLine(value: string) {
    if (!value) {
        return ""
    }

    return value.replace(/(?:\r\n|\r|\n)/g, "<br>")
}

export function cleanHtml(html: string) {
    return sanitizeHtml(html, {
        allowedTags: ["br", "span"],
        allowedAttributes: {
            "*": ["class", "style"]
        }
    })
}

export function buildQueryString(values: any, ignores: any) {
    const queryValues = Object.assign({}, values)

    for (let index = 0; index < ignores.length; index++) {
        delete queryValues[ignores[index]]
    }

    return new URLSearchParams(queryValues).toString()
}

export function getStatusColor(statusCode: string): any {
    /*
        2 - Betting
        4 5 6 - EndedBetting / WaitingResult / VerifyingResult
        7 - SettledResult
        3 - Cancelled
    */

    if (statusCode === "endedBetting") {
        return {
            icon: "fill-accent-1",
            text: "",
            bg: "bg-accent-1"
        }
    } else if (statusCode === "settledResult") {
        return {
            icon: "fill-[#15A5D2]",
            text: "text-white",
            bg: "bg-[#15A5D2]"
        }
    } else if (statusCode === "cancelled") {
        return {
            icon: "fill-[#E6E6E6]",
            text: "text-[#575757]",
            bg: "bg-[#E6E6E6]"
        }
    }

    // default "betting"
    return {
        icon: "fill-accent-2",
        text: "text-white",
        bg: "bg-accent-2"
    }
}

export function getTitle1(gameType) {
    return gameType === GameType.PREDICTION ? "positiveView" : "insurancePool"
}

export function getTitle2(gameType) {
    return gameType === GameType.PREDICTION ? "negativeView" : "policyHolder"
}

export function toTitleCase(str) {
    if (!str) {
        return ""
    }

    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export function compareWallet(left: string, right: string): boolean {
    const isEqual = left === right
    if (!isEqual) {
        if (left && right) {
            return left.toLowerCase() === right.toLowerCase()
        }
    }

    return isEqual
}

export function stripHtml(value) {
    if (!value) {
        return value
    }

    return value.replaceAll("&nbsp;", " ").replace(/(<([^>]+)>)/gi, "")
}

export function wordsCount(value): number {
    if (!value) {
        return 0
    }

    return stripHtml(value).split(" ").length
}

export function isAddress(address): boolean {
    if (!address) {
        return false
    }

    return utils.isAddress(address)
}

export function isEmail(email) {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function generateCode(length = 6) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
    let retVal = ""
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n)).toUpperCase()
    }

    return retVal
}
