"use client"

import React, { useEffect, useState } from "react"
import { Loading } from "../../components/shared/Loading"

function AppLoading({ children }) {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        setTimeout(function () {
            console.log("run setTimeout")
            if (typeof window === "undefined" || !window?.ethereum) {
                console.log("Not found window ethereum")

                setLoaded(true)
            }
        }, 100)

        setTimeout(function () {
            console.log("Timeout for connecting")

            setLoaded(true)
        }, 1000)
    }, [])

    if (loaded) {
        return children
    }

    return <Loading />
}

export default AppLoading
