import type { AppProps } from "next/app"
import "../styles/globals.css"
import Modal from "react-modal"
import Script from "next/script"
import { ToastContainer } from "react-toastify"
import { appWithTranslation } from "next-i18next"
import "react-toastify/dist/ReactToastify.css"
import "@rainbow-me/rainbowkit/styles.css"
import GlobalContextProvider from "../contexts/GlobalContext"

import { connectorsForWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit"
import {
    coinbaseWallet,
    metaMaskWallet,
    okxWallet,
    rainbowWallet,
    walletConnectWallet
} from "@rainbow-me/rainbowkit/wallets"
import { configureChains, createConfig, WagmiConfig } from "wagmi"
import { arbitrum, bsc } from "wagmi/chains"
import { publicProvider } from "wagmi/providers/public"
import { arbitrumGoerli, bscTestnet } from "viem/chains"
import AppLoading from "../contexts/MetamaskContext"
import { SUPPORTED_NETWORK, SupportedChainIds } from "../utils/network"

const supportChains = []
if (SupportedChainIds.indexOf(+SUPPORTED_NETWORK.BSC.chainId.toString()) >= 0) {
    supportChains.push(bsc)
}

if (SupportedChainIds.indexOf(+SUPPORTED_NETWORK.BSC_TEST.chainId.toString()) >= 0) {
    supportChains.push(bscTestnet)
}
if (SupportedChainIds.indexOf(+SUPPORTED_NETWORK.ARBITRUM.chainId.toString()) >= 0) {
    supportChains.push(arbitrum)
}

if (SupportedChainIds.indexOf(+SUPPORTED_NETWORK.ARBITRUM_TESTNET.chainId.toString()) >= 0) {
    supportChains.push(arbitrumGoerli)
}

const { chains, publicClient } = configureChains(supportChains, [publicProvider()])

const projectId = "8dc32d14d24b17cceec2f2b7a7142407"

const AppInfo = {
    appName: "BBB"
}

const connectors = connectorsForWallets([
    {
        groupName: "Supports",
        wallets: [
            metaMaskWallet({ chains, projectId }),
            rainbowWallet({ chains, projectId }),
            walletConnectWallet({ chains, projectId }),
            coinbaseWallet({ chains, appName: projectId }),
            okxWallet({ chains, projectId })
        ]
    }
])

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
})

function NextWeb3App({ Component, pageProps }: AppProps) {
    Modal.setAppElement("#react-modal")

    return (
        <>
            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                strategy="afterInteractive"
            />
            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());
                    
                    gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
                `}
            </Script>
            <div id="react-modal"></div>
            <AppLoading>
                <WagmiConfig config={wagmiConfig}>
                    <RainbowKitProvider chains={chains} appInfo={AppInfo}>
                        <GlobalContextProvider>
                            <Component {...pageProps} />
                            <ToastContainer />
                        </GlobalContextProvider>
                    </RainbowKitProvider>
                </WagmiConfig>
            </AppLoading>
        </>
    )
}

export default appWithTranslation(NextWeb3App)
