import React, { useEffect, useState } from "react"
import useStorage from "../../hooks/useStorage"
import { Context } from "./index"
import axios from "axios"
import { getStorage, setStorage } from "../../utils/storage"
import { useRouter } from "next/router"
import useBBBNetwork from "../../hooks/useBBBNetwork"
import { ApiUrl } from "../../utils/network"
import { compareWallet } from "../../utils/string"
import { useAccount, useNetwork } from "wagmi"

const user_storage_key = "user_data"

function tryRefreshToken(loggedUser, success, failed, error) {
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshToken: loggedUser.refreshToken })
    }

    delete loggedUser.refreshToken
    setStorage(user_storage_key, loggedUser)

    fetch(`${ApiUrl}/api/v1/auth/customers/refresh`, requestOptions)
        .then(async (response) => {
            const isJson = response.headers.get("content-type")?.includes("application/json")
            const data = isJson && (await response.json())

            if (response.ok) {
                setStorage(user_storage_key, {
                    ...loggedUser,
                    ...data.data
                })

                if (typeof success === "function") {
                    success(data.data)
                }
            } else {
                if (typeof failed === "function") {
                    failed()
                }
            }
        })
        .catch((error) => {
            if (typeof error === "function") {
                error()
            }
        })
}

function setupAxios(currentUser, setCurrentUser, requiredLoginCallback, locale, chainId) {
    axios.defaults.headers.common["x-locale"] = locale || "en"
    axios.defaults.headers.common["x-chain-id"] = chainId

    axios.interceptors.request.clear()
    axios.interceptors.response.clear()

    axios.interceptors.request.use(
        (config) => {
            //console.info("Request: ", config)

            if (typeof window !== "undefined") {
                config.headers["x-chain-id"] = chainId || window.localStorage.chainId
            }

            const loggedUser = getStorage(user_storage_key, {})
            if (loggedUser.tokenExpire) {
                // try refresh token before expired (30 minutes)
                const paddingMinutes = 30 * 60 * 1000
                const timeBeforeExpired = new Date(loggedUser.tokenExpire * 1000 - paddingMinutes)
                if (loggedUser.refreshToken && timeBeforeExpired < new Date()) {
                    return new Promise(function (resolve, reject) {
                        tryRefreshToken(
                            loggedUser,
                            (data) => {
                                config.headers["Authorization"] = `Bearer ${data.token}`

                                resolve(config)
                            },
                            () => {
                                setStorage(user_storage_key, {})

                                reject(config)
                            },
                            () => {
                                setStorage(user_storage_key, {})

                                reject(config)
                            }
                        )
                    })
                }
            }

            if (loggedUser.token) {
                config.headers["Authorization"] = `Bearer ${loggedUser.token}`
            }

            return config
        },
        (error) => {
            //console.error("Error: ", error)

            return Promise.reject(error)
        }
    )

    axios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            //console.error("Response Error: ", error)

            if (error.response && error.response.status === 401) {
                const requiredLogin = () => {
                    setStorage(user_storage_key, {})

                    if (typeof requiredLoginCallback === "function") {
                        requiredLoginCallback()
                    }
                }

                const loggedUser = getStorage(user_storage_key, {})
                if (loggedUser.refreshToken) {
                    tryRefreshToken(
                        loggedUser,
                        null,
                        () => {
                            requiredLogin()
                        },
                        () => {
                            requiredLogin()
                        }
                    )
                } else {
                    requiredLogin()
                }
            }

            return Promise.reject(error)
        }
    )
}

export interface PopupMessageProps {
    message: string
    type?: "ERROR" | "COMMON"
    buttonText?: string
    onClick?: () => void
}

const GlobalContextProvider = (props) => {
    const router = useRouter()
    const { currentChainId } = useBBBNetwork()
    const { address: account } = useAccount()

    const {
        chain: { id: chainId }
    } = useBBBNetwork()

    const [currentUser, setCurrentUser] = useStorage(user_storage_key, {})
    const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
    const [popupMessage, setPopupMessage] = useState<PopupMessageProps>(null)

    useEffect(() => {
        setupAxios(
            currentUser,
            setCurrentUser,
            () => {
                setIsOpenLoginModal(true)
            },
            router.locale,
            chainId
        )
    }, [currentUser, currentChainId, chainId])

    setupAxios(null, null, null, router.locale, chainId)

    return (
        <Context.Provider
            value={{
                isLoggedIn: currentUser && currentUser.id,
                isValidAccount:
                    currentUser && currentUser.id && !!account && compareWallet(currentUser.walletAddress, account),
                account,
                user: currentUser,
                setUser: setCurrentUser,
                isOpenLoginModal,
                setIsOpenLoginModal,
                popupMessage,
                setPopupMessage
            }}
        >
            {props.children}
        </Context.Provider>
    )
}

export default GlobalContextProvider
