import React, { useEffect, useState } from "react"
import { BallSvg, BasketBallSvg, CryptoSvg } from "../../assets/svg"
import { twMerge } from "tailwind-merge"
import useHasWindow from "../../hooks/useHasWindow"
import clsx from "clsx"

interface LoadingProps {
    iconColor?: string
    overlayClassName?: string
}

export const Loading = ({ iconColor = "fill-accent-1", overlayClassName }: LoadingProps) => {
    const hasWindow = useHasWindow()

    const animationClasName = "animate-[zoom-in-zoom-out_800ms_ease-in-out]"

    const [animationClass1, setAnimationClass1] = useState(animationClasName)
    const [animationClass2, setAnimationClass2] = useState("")
    const [animationClass3, setAnimationClass3] = useState("")

    const [layer, setLayer] = useState(1)
    useEffect(() => {
        const timerId = setInterval(() => {
            setLayer((prevState) => prevState + 1)
        }, 500)

        return () => {
            clearInterval(timerId)
        }
    }, [])

    useEffect(() => {
        if (layer % 3 === 1) {
            setAnimationClass1(animationClasName)
            setAnimationClass2("")
        } else if (layer % 3 === 2) {
            setAnimationClass2(animationClasName)
            setAnimationClass3("]")
        } else if (layer % 3 === 0) {
            setAnimationClass1("")
            setAnimationClass3(animationClasName)
        }
    }, [layer])

    return (
        <>
            {hasWindow && (
                <div className={"b-flex-center absolute top-0 left-0 z-10 h-full w-full"}>
                    <div
                        className={twMerge(
                            clsx(
                                "absolute top-0 h-full w-full rounded-[5px] bg-neutral-1-900 opacity-60",
                                overlayClassName
                            )
                        )}
                    ></div>
                    <div className={"b-flex-center relative child:m-4 child:h-[30px] child:w-[30px]"}>
                        <BallSvg className={twMerge(animationClass1, iconColor)} />
                        <BasketBallSvg className={twMerge(animationClass2, iconColor)} />
                        <CryptoSvg className={twMerge(animationClass3, iconColor)} />
                    </div>
                </div>
            )}
        </>
    )
}
