import { BigNumber, utils } from "ethers"
import { EmptyAddress, setupNetwork } from "./wallet"
import { ContractAddresses } from "../contracts/ContractAddresses"
import { GameOddsType, GameType } from "../services/games"
import { parseEther } from "./number"
import {
    BnbLogo,
    BusdLogo,
    esBBBLogo,
    EthLogo,
    PolygonLogo,
    ThunderCoreLogo,
    UsdcLogo,
    UsdtLogo
} from "../assets/svg/string"

export const SUPPORTED_NETWORK = {
    BSC: {
        chainId: "0x38", // 56
        chainName: "Binance Smart Chain Mainnet",
        nativeCurrency: {
            name: "BNB",
            symbol: "BNB",
            decimals: 18
        },
        rpcUrls: ["https://bsc-dataseed.binance.org"],
        blockExplorerUrls: ["https://bscscan.com"]
    },

    BSC_TEST: {
        chainId: "0x61", // 97
        chainName: "Binance Smart Chain Testnet",
        nativeCurrency: {
            name: "BNB",
            symbol: "tBNB",
            decimals: 18
        },
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls: ["https://testnet.bscscan.com"]
    },

    THUNDERCORE: {
        chainId: "0x6C", // 108
        chainName: "ThunderCore Mainnet",
        nativeCurrency: {
            name: "TT",
            symbol: "TT",
            decimals: 18
        },
        rpcUrls: ["https://mainnet-rpc.thundercore.com"],
        blockExplorerUrls: ["https://viewblock.io/thundercore"]
    },

    THUNDERCORE_TESTNET: {
        chainId: "0x12", // 18
        chainName: "ThunderCore Testnet",
        nativeCurrency: {
            name: "TST",
            symbol: "TST",
            decimals: 18
        },
        rpcUrls: ["https://testnet-rpc.thundercore.com"],
        blockExplorerUrls: ["https://explorer-testnet.thundercore.com"]
    },

    POLYGON: {
        chainId: "0x89", // 137
        chainName: "Polygon Mainnet",
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
        },
        rpcUrls: ["https://polygon-rpc.com"],
        blockExplorerUrls: ["https://polygonscan.com"]
    },

    POLYGON_TESTNET: {
        chainId: "0x13881", // 80001
        chainName: "Mumbai",
        nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18
        },
        rpcUrls: ["https://endpoints.omniatech.io/v1/matic/mumbai/public"],
        blockExplorerUrls: ["https://mumbai.polygonscan.com"]
    },

    ARBITRUM: {
        chainId: "0xA4B1", // 42161
        chainName: "Arbitrum One",
        nativeCurrency: {
            name: "ETH",
            symbol: "ETH",
            decimals: 18
        },
        rpcUrls: ["https://endpoints.omniatech.io/v1/arbitrum/one/public"],
        blockExplorerUrls: ["https://arbiscan.io/"]
    },

    ARBITRUM_TESTNET: {
        chainId: "0x66EED", // 421613
        chainName: "Arbitrum Goerli",
        nativeCurrency: {
            name: "AGOR",
            symbol: "AGOR",
            decimals: 18
        },
        rpcUrls: ["https://endpoints.omniatech.io/v1/arbitrum/goerli/public"],
        blockExplorerUrls: ["https://goerli-rollup-explorer.arbitrum.io"]
    }
}

export const SupportedChainIds = process.env.NEXT_PUBLIC_NETWORK_IDS.split(",").map((x) => +x)

export const AllSupportedChainIds = [56, 97, 108, 18, 137, 80001]

export const AllSupportedMainnetChainIds = [56, 108, 137, 42161]

export const AllSupportedTestnetChainIds = [97, 18, 80001, 421613]

export const BscChainIds = [56, 97]

export const ThunderCoreChainIds = [108, 18]

export const PolygonChainIds = [137, 80001]

export const ArbitrumChainIds = [42161, 421613]

export const findSupportNetwork = (chainId: number) => {
    if (!chainId) {
        return null
    }

    for (const property in SUPPORTED_NETWORK) {
        const network = SUPPORTED_NETWORK[property]

        if (network.chainId === utils.hexlify(chainId)) {
            return network
        }
    }

    return null
}

export const switchToChain = async (chainId: number) => {
    const network = findSupportNetwork(chainId)
    if (network) {
        await setupNetwork(network)
    }
}

export const getSupportTokens = (chainId: number) => {
    if (ThunderCoreChainIds.indexOf(chainId) >= 0) {
        return [
            {
                id: "TT",
                label: "TT",
                logo: ThunderCoreLogo
            } /*,
            {
                id: "BUSD",
                label: "BUSD"
            }*/
        ]
    }

    if (PolygonChainIds.indexOf(chainId) >= 0) {
        return [
            {
                id: "MATIC",
                label: "MATIC",
                icon: PolygonLogo
            },
            {
                id: "USDT",
                label: "USDT",
                icon: UsdtLogo
            },
            {
                id: "USDC",
                label: "USDC",
                icon: UsdcLogo
            }
        ]
    }

    if (ArbitrumChainIds.indexOf(chainId) >= 0) {
        return [
            {
                id: "ETH",
                label: "ETH",
                icon: EthLogo
            },
            {
                id: "USDT",
                label: "USDT",
                icon: UsdtLogo
            },
            {
                id: "USDC",
                label: "USDC",
                icon: UsdcLogo
            }
        ]
    }

    return [
        {
            id: "BNB",
            label: "BNB",
            icon: BnbLogo
        },
        {
            id: "USDT",
            label: "USDT",
            icon: UsdtLogo
        },
        {
            id: "USDC",
            label: "USDC",
            icon: UsdcLogo
        },
        {
            id: "BUSD",
            label: "BUSD",
            icon: BusdLogo
        },
        {
            id: "esBBB",
            label: "esBBB",
            icon: esBBBLogo
        }
    ]
}

export const getContractAddress = (chainId: number) => {
    return ContractAddresses.betting.router[chainId]
}

export const getTokenAddress = (tokenName: string, chainId: number) => {
    if (tokenName) {
        tokenName = tokenName.toLowerCase()

        const tokens = ContractAddresses.token
        switch (tokenName) {
            case "busd":
                return tokens.busd[chainId]
            case "wbnb":
                return tokens.wBnb[chainId]
            case "tt":
                return tokens.tt[chainId]
            case "bnb":
                return tokens.bnb[chainId]
            case "usdt":
                return tokens.usdt[chainId]
            case "usdc":
                return tokens.usdc[chainId]
            case "matic":
                return tokens.matic[chainId]
            case "eth":
                return tokens.eth[chainId]
            case "esbbb":
                return tokens.esBBB[chainId]
        }
    }

    return EmptyAddress
}

export const isNativeToken = (tokenName: string) => {
    return ["tt", "bnb", "eth", "matic"].indexOf(tokenName.toLowerCase()) >= 0
}

export const getContractOption = (gameChainId: number, tokenName: string, amount: any) => {
    return isNativeToken(tokenName)
        ? { value: parseEther(gameChainId, tokenName, amount) }
        : { value: BigNumber.from(0) }
}

export const getGameTypeContract = (gameType: GameType, gameOddsType?: GameOddsType) => {
    if (gameType === GameType.INSURANCE) {
        return 2
    }

    if (gameType === GameType.PREDICTION && gameOddsType === GameOddsType.FIXED) {
        return 1
    }

    return 0
}

export const ApiUrl = process.env.NEXT_PUBLIC_API_URL
// export const ApiUrl = "https://dev-api.bbb.wtf"
