export const ContractAddresses = {
    token: {
        busd: {
            // BSC
            56: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
            97: "0x26e7d1DF8499f6D539732eb6Bface64C5F525647",

            // THUNDER CORE
            18: "0x34dD5216F618288d7AeE88cA8858ad02AF399324",
            108: "0xBEB0131D95AC3F03fd15894D0aDE5DBf7451d171"
        },
        wBnb: {
            // BSC
            56: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            97: "0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F"
        },
        tt: {
            // THUNDER CORE
            18: "0x390127e81ede57cfa1e65ea9a96bd68a59d0a099",
            108: "0x413cEFeA29F2d07B8F2acFA69d92466B9535f717"
        },

        bnb: {
            // BSC
            56: "0x0000000000000000000000000000000000000000",
            97: "0x0000000000000000000000000000000000000000"
        },

        usdc: {
            // BSC
            56: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
            97: "0x52ED017Fe5740DD23cFd91Ca333A8AaD0f1b9e7e",

            // THUNDER CORE
            18: "0x0000000000000000000000000000000000000000",
            108: "0x0000000000000000000000000000000000000000",

            // POLYGON
            137: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
            80001: "0x83b2895d9fCF9341E3Bb8C30d0dE38a4C87e60C4",

            // ARBITRUM
            42161: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
            421613: "0x349ef41233EeD1ce04c37eEBF7b473C24b884a62"
        },

        usdt: {
            // BSC
            56: "0x55d398326f99059fF775485246999027B3197955",
            97: "0xBaeaa0Cb023437496C7A27B163Ea762B3e30A64A",

            // THUNDER CORE
            18: "0x0000000000000000000000000000000000000000",
            108: "0x0000000000000000000000000000000000000000",

            // POLYGON
            137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
            80001: "0x34dD5216F618288d7AeE88cA8858ad02AF399324",

            // ARBITRUM
            42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
            421613: "0x2CCCA2D69000cb914769f6189e31F6F888db0f9b"
        },

        matic: {
            // POLYGON
            137: "0x0000000000000000000000000000000000000000",
            80001: "0x0000000000000000000000000000000000000000"
        },

        eth: {
            // POLYGON
            137: "0x0000000000000000000000000000000000000000",
            80001: "0x0000000000000000000000000000000000000000",

            // ARBITRUM
            42161: "0x0000000000000000000000000000000000000000",
            421613: "0x0000000000000000000000000000000000000000"
        },

        esBBB: {
            97: "0x394160a267f2153e86a70133ad7c1e99597b3b41",
            56: "0xA58C1026558db749FB83681275e31dC4D39828BE"
        }
    },
    betting: {
        router: {
            // BSC
            56: "0x13dfCE0069A618F366CDF538A6e97D5eA407077A",
            97: "0x46773f0ED974f2A23ab8A7acd7850F42b8aFB7A7",

            // THUNDER CORE
            18: "0x0",
            108: "0x0",

            // POLYGON
            137: "0x0",
            80001: "0x0",

            // ARBITRUM
            42161: "0x3dd731DfE3532b8f27969b25AA66299e8A318B2d",
            421613: "0x0"
        }
    },
    esBBBDistributor: {
        97: "0xC5Bb2b27c8f0C27603e72C3016A6a6D357efc525",
        56: "0xB090033a519F5f3393E45cA686B747DC37cCc69c"
    }
}
