import { useState } from "react"

const useLocalStorage = (key: string, initialValue: any) => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === "undefined") {
            return initialValue
        }

        try {
            const item = window.localStorage.getItem(key)

            return item ? JSON.parse(item) : initialValue
        } catch (error) {
            console.log(error)

            window.localStorage.setItem(key, JSON.stringify({}))

            return initialValue
        }
    })

    const setValue = (value: any) => {
        try {
            if (typeof window === "undefined") {
                return
            }

            const valueToStore = value instanceof Function ? value(storedValue) : value

            setStoredValue(valueToStore)

            window.localStorage.setItem(key, JSON.stringify(valueToStore))
        } catch (error) {
            console.log(error)
        }
    }

    return [storedValue, setValue]
}

export default useLocalStorage
