import { SUPPORTED_NETWORK } from "./network"

export const setupNetwork = async (network = SUPPORTED_NETWORK.BSC, library?, onChangeSuccess?) => {
    // @ts-ignore
    let { ethereum } = window

    console.log("Switch library", library)
    if (library) {
        ethereum = library.provider
    }

    try {
        //  @ts-ignore
        await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: network.chainId }]
        })

        if (typeof onChangeSuccess === "function") {
            onChangeSuccess()
        }
    } catch (switchError) {
        console.log(switchError)

        if (switchError.code === 4902) {
            try {
                //  @ts-ignore
                await ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [network]
                })
            } catch (addError) {
                console.log(addError)
            }
        }
    }
}

export const isValidAddress = (address: string) => {
    return /^(0x)?[0-9a-fA-F]{40}$/.test(address)
}

export const addToken = async (address: string, symbol: string) => {
    try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        // @ts-ignore
        await window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
                type: "ERC20", // Initially only supports ERC20, but eventually more!
                options: {
                    address: address, // The address that the token is at.
                    symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
                    decimals: 18, // The number of decimals in the token
                    image: "" // A string url of the token logo
                }
            }
        })
    } catch (error) {
        console.log(error)
    }
}

export const EmptyAddress = "0x0000000000000000000000000000000000000000"
