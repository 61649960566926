import { useEffect, useState } from "react"
import {
    AllSupportedChainIds,
    AllSupportedMainnetChainIds,
    AllSupportedTestnetChainIds,
    ArbitrumChainIds,
    BscChainIds,
    PolygonChainIds,
    SupportedChainIds,
    ThunderCoreChainIds
} from "../utils/network"
import { useNetwork } from "wagmi"

export default function useBBBNetwork() {
    const { chain } = useNetwork()
    const [currentChainId, setCurrentChainId] = useState(0)

    let timerInterval

    let retryTimes = 0

    const [currentChain, setCurrentChain] = useState({
        id: 0
    })
    useEffect(() => {
        if (chain) {
            setCurrentChain(chain)
            setCurrentChainId(chain.id)

            if (timerInterval) {
                clearInterval(timerInterval)
            }
        } else {
            timerInterval = setInterval(() => {
                if (typeof window !== "undefined" && window.ethereum && currentChainId === 0) {
                    // @ts-ignore
                    const chainId = parseInt(window.ethereum.chainId)

                    if (SupportedChainIds.indexOf(chainId) === -1 && AllSupportedChainIds.indexOf(chainId) >= 0) {
                        window.location.href =
                            AllSupportedMainnetChainIds.indexOf(chainId) >= 0
                                ? process.env.NEXT_PUBLIC_BASE_URL
                                : process.env.NEXT_PUBLIC_TEST_BASE_URL

                        if (timerInterval) {
                            clearInterval(timerInterval)
                        }
                    }

                    setCurrentChainId(chainId)

                    window.localStorage.chainId = chainId
                }

                if (currentChainId > 0 && timerInterval) {
                    clearInterval(timerInterval)
                }

                if (retryTimes >= 10 && currentChainId === 0) {
                    setCurrentChainId(56)

                    if (timerInterval) {
                        clearInterval(timerInterval)
                    }
                }

                retryTimes++
            }, 50)
        }

        return () => {
            if (timerInterval) {
                clearInterval(timerInterval)
            }
        }
    }, [chain])

    return {
        currentChainId: currentChainId,
        isTestnet: AllSupportedTestnetChainIds.indexOf(currentChainId) >= 0 && currentChainId > 0,
        isBsc: BscChainIds.indexOf(currentChainId) >= 0,
        isThunderCore: ThunderCoreChainIds.indexOf(currentChainId) >= 0,
        isPolygon: PolygonChainIds.indexOf(currentChainId) >= 0,
        isArbitrum: ArbitrumChainIds.indexOf(currentChainId) >= 0,
        chain: currentChain
    }
}
