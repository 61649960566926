import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import customParseFormat from "dayjs/plugin/customParseFormat"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"

dayjs.extend(isSameOrAfter)
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

import("dayjs/locale/zh")

export function getUnixTimestamp(timestamp: number, timezone: any) {
    if (!timestamp) {
        return
    }

    return dayjs.tz(dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss"), timezone.value || timezone).valueOf() / 1000
}

export function timeFromNow(unixTimestamp: number) {
    if (!unixTimestamp) {
        return
    }

    return dayjs(unixTimestamp * 1000).fromNow()
}

export function isBeforeToday(unixTimestamp: number) {
    if (!unixTimestamp) {
        return
    }

    return dayjs(unixTimestamp * 1000).isBefore(dayjs())
}

export function isAfterToday(unixTimestamp: number) {
    if (!unixTimestamp) {
        return
    }

    return dayjs(unixTimestamp * 1000).isAfter(dayjs())
}

export function formatUnixTimestamp(unixTimestamp: number) {
    return formatDate(unixTimestamp, "DD/MM/YYYY")
}

export function formatUnixTimestampLong(unixTimestamp: number) {
    return formatDate(unixTimestamp, "DD/MM/YYYY HH:mm")
}

export function formatTime(unixTimestamp: number) {
    return formatDate(unixTimestamp, "HH:mm")
}

export function formatLongDate(unixTimestamp: number) {
    return formatDate(unixTimestamp, "MMMM DD YYYY")
}

export function formatMediumTimezoneDate(unixTimestamp: number) {
    return formatDate(unixTimestamp, "MMM DD, YYYY hh:mm A UTCZ")
}

export function formatDate(unixTimestamp: number, template: string) {
    if (!unixTimestamp) {
        return
    }

    return dayjs(unixTimestamp * 1000).format(template)
}

export function setMaxSecond(unixTimestamp: number) {
    if (!unixTimestamp) {
        return
    }

    return dayjs(dayjs(unixTimestamp * 1000).format("YYYY-MM-DDTHH:mm:59")).valueOf()
}
