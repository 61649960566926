import axios from "axios"
import { getUnixTimestamp } from "../utils/date"
import { isValidAddress } from "../utils/wallet"
import { ApiUrl } from "../utils/network"

export enum ViewType {
    GRID = 1,
    LIST = 2
}

export enum GameStageEnum {
    HOST = 1,
    PARTICIPANT = 2,
    PAST = 3
}

export enum GameReactEnum {
    NONE = 0,
    LIKE = 1,
    DISLIKE = 2
}

export enum NotificationTypeEnum {
    DEBATE_INVITE = 1,
    DEBATE_CREATE_SUCCESS = 2,
    DEBATE_REMIND_INTERMEDIARY = 3,
    INSURANCE_REMIND_INTERMEDIARY = 4
}

export enum GameStatusEnum {
    CREATE_PENDING = 0, // Tạo game chưa sync smartcontract
    CREATE_HASH_UPDATED = 1, // Đã sync smartcontract
    CREATE_APPROVED = 2, // Game đc approve
    CREATE_REJECTED = 3, // Game bị reject
    RESULT_ALLOW_UPDATE = 4, // Game hết hạn và cho phép host update kết quả
    RESULT_UPDATED = 5, // Game đã update kết quả, chờ admin duyệt hoặc bị report
    RESULT_REPORTED = 6, // Game bị report
    RESULT_APPROVED = 7 // Game đã chốt kết quả cuối cùng
}

export enum GameSortOptions {
    NONE = "",
    TRENDING = "TRENDING",
    NEW = "NEW",
    VOLUME = "VOLUME",
    EXPIRED = "EXPIRED",
    LIQUIDITY = "LIQUIDITY",
    CLOSED = "CLOSED",
    ALL = "ALL",
    EXPIRING = "EXPIRING",
    HOME = "HOME"
}

export enum GameJudgementTypeEnum {
    VOTE = 0,
    POINT = 1,
    INTERMEDIARY = 2
}

export enum GameType {
    PREDICTION = 1,
    DEBATE = 2,
    INSURANCE = 3
}

export enum GameOddsType {
    DYNAMIC = 1,
    FIXED = 2
}

export enum MyGameOptions {
    HOST = 1,
    PARTICIPANT = 2,
    PAST = 3
}

export enum GameInviteStatusEnum {
    NEW = 0,
    ACCEPTED = 1,
    DECLINED = 2,
    EXPIRED = 3
}

export async function getGames(
    advanceSort: GameSortOptions = GameSortOptions.TRENDING,
    page: number = 1,
    limit: number = 20,
    sortField: string = "createdAt",
    sortDirection: number = -1,
    keyword?: string,
    gameType?: GameType,
    status?: string,
    searchLocale: string = "",
    tag: string = "",
    tokenName: string = ""
) {
    const url = `${ApiUrl}/api/v1/games`

    return axios.get(url, {
        params: {
            keyword: keyword,
            tokenName: tokenName,
            gameType: gameType,
            statusText: status,
            searchLocale: searchLocale,
            hashtagId: tag,
            advanceSort: advanceSort ? (advanceSort === GameSortOptions.ALL ? "" : advanceSort) : advanceSort,
            orderBy: sortField,
            order: sortDirection,
            page: page,
            limit: limit
        }
    })
}

export async function getGameRecommended() {
    const url = `${ApiUrl}/api/v1/games/recommends`

    return axios.get(url)
}

export async function getGameDetails(slug: string) {
    const url = `${ApiUrl}/api/v1/games/${slug}`

    return axios.get(url)
}

export async function createGame(data: any) {
    const url = `${ApiUrl}/api/v1/games`

    const formData = new FormData()
    //formData.append("category", data.category.id)
    formData.append("locale", data.locale)
    formData.append("title", data.title)
    formData.append("type", data.type)

    if (data.type === GameType.PREDICTION) {
        formData.append("selections[]", data.positiveView)
        formData.append("selections[]", data.negativeView)
        //formData.append("selections", `${data.positiveView},${data.negativeView}`)
    } else if (data.type === GameType.INSURANCE) {
        formData.append("projectLogoImage", data.projectLogoImage)
        formData.append("projectName", data.projectName)
        formData.append("projectDescription", data.projectDescription)
        formData.append("projectEmail", data.projectEmail)
        formData.append("projectWebsite", data.projectWebsite)
        formData.append("projectTwitterLink", data.projectTwitterLink)
        formData.append("projectDiscordLink", data.projectDiscordLink)
        formData.append("projectTelegramLink", data.projectTelegramLink)
        formData.append("selections", data.answerType)
    }

    formData.append(
        "whitelistAddresses",
        data.whitelistAddresses
            ? data.whitelistAddresses
                  .split(/[\r\n,]/)
                  .map((x) => x.trim())
                  .filter((x) => x && isValidAddress(x))
                  .join(",")
            : ""
    )

    formData.append("intermediaryWalletAddress", data.intermediaryWalletAddress)
    formData.append("oddsType", data.oddsType)
    if (data.oddsType === GameOddsType.FIXED) {
        formData.append("hostOdds", data.hostOdds)
        formData.append("counterpartOdds", data.counterpartOdds)
    }
    formData.append("hostNotifyEmails", data.email || "")
    formData.append(
        "hashtags",
        data.hashTags.map((x) => x.value)
    )
    formData.append("mainImage", data.banner || null)
    formData.append("description", data.description)
    formData.append("predictResult", data.predictResult)
    formData.append("hostStake", data.stake)
    formData.append("minimumStake", data.minimumStake)
    formData.append("maximumStake", data.maximumStake)
    formData.append("expiredAt", getUnixTimestamp(+data.deadline, data.timezone).toString())
    formData.append("resultAnnouncementAt", getUnixTimestamp(+data.announceResult, data.timezone).toString())
    formData.append("tokenName", data.tokenName)

    return axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
}

export async function getMyGames(
    type: MyGameOptions = MyGameOptions.HOST,
    category: string = "",
    title: string = "",
    page: number = 1,
    limit: number = 20,
    sortField: string = "createdAt",
    sortDirection: number = -1,
    gameType: number = 0,
    searchLocale: string = "",
    statusText: string = "",
    status: string = "",
    tokenName: string = ""
) {
    const url = `${ApiUrl}/api/v1/customers/my-games`

    return axios.get(url, {
        params: {
            status: status,
            tokenName: tokenName,
            gameType: gameType || "",
            searchLocale: searchLocale,
            statusText: statusText,
            category: category,
            title: title,
            orderBy: sortField,
            order: sortDirection,
            page: page,
            limit: limit
        }
    })
}

export async function hostReportGame(gameId: string, data: any) {
    const url = `${ApiUrl}/api/v1/customers/host-reports`

    const formData = new FormData()
    formData.append("game", gameId)
    formData.append("hostReportReason", data.reason)
    formData.append("hostReportDescription", data.description)
    for (let index = 0; index < data.attachments.length; index++) {
        formData.append("hostReportFiles[]", data.attachments[index])
    }

    return axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" }
    })
}

export async function getGameBoots(
    categoryId: string,
    gameType: GameType = GameType.PREDICTION,
    page: number = 1,
    limit: number = 20,
    sortField: string = "createdAt",
    sortDirection: number = -1
) {
    const url = `${ApiUrl}/api/v1/boosts`

    return axios.get(url, {
        params: {
            gameType: gameType,
            gameCategory: categoryId,
            orderBy: sortField,
            order: sortDirection,
            page: page,
            limit: limit
        }
    })
}

export async function getGameBets(
    gameId: string,
    from: string,
    page: number = 1,
    limit: number = 20,
    sortField: string = "createdAt",
    sortDirection: number = -1
) {
    const url = from === "MY" ? `${ApiUrl}/api/v1/game-bets/me` : `${ApiUrl}/api/v1/game-bets`

    return axios.get(url, {
        params: {
            gameId: gameId,
            orderBy: sortField,
            order: sortDirection,
            page: page,
            limit: limit
        }
    })
}

export async function acceptInvitation(id: string, gameType: GameType = GameType.DEBATE) {
    const url = `${ApiUrl}/api/v1/games/${id}/invitations`

    const payload = {
        gameType: gameType,
        action: 1
    }

    return axios.put(url, payload)
}

export async function declineInvitation(id: string, gameType: GameType = GameType.DEBATE) {
    const url = `${ApiUrl}/api/v1/games/${id}/invitations`

    const payload = {
        gameType: gameType,
        action: 2
    }

    return axios.put(url, payload)
}

export async function reactGame(id: string, action: GameReactEnum) {
    const url = `${ApiUrl}/api/v1/game-reacts`

    const payload = {
        gameId: id,
        action: action
    }

    return axios.post(url, payload)
}

export async function getLinkByHash(hash: string) {
    const url = `${ApiUrl}/api/v1/unique-hashed/${hash}`

    return axios.get(url)
}

export async function updateResultReason(id: string, reason: string) {
    if (!id || !reason) {
        return
    }

    const url = `${ApiUrl}/api/v1/games/${id}/result-explanations`

    return axios.put(url, {
        explanation: reason
    })
}

export async function getHomeSliders() {
    const url = `${ApiUrl}/api/v1/home-sliders?limit=10&page=1`

    return axios.get(url)
}

export async function getHashTags() {
    const url = `${ApiUrl}/api/v1/hashtags`

    return axios.get(url)
}

export async function getPlayerData(walletAddress) {
    const url = `${ApiUrl}/api/v1/customers/game-statistics`

    return axios.get(url, {
        params: {
            walletAddress
        }
    })
}

export async function addPageView(gameId: string) {
    if (!gameId) {
        return
    }
    const url = `${ApiUrl}/api/v1/games/page-views/${gameId}`

    return axios.get(url)
}
