export function getStorage(key: string, defaultValue: any) {
    if (typeof window === "undefined") {
        return defaultValue
    }

    try {
        return JSON.parse(window.localStorage.getItem(key)) || defaultValue
    } catch (ex) {
        console.log(ex)

        return defaultValue
    }
}

export function setStorage(key: string, value: any) {
    if (typeof window === "undefined") {
        return
    }

    try {
        window.localStorage.setItem(key, JSON.stringify(value))
    } catch (ex) {
        console.log(ex)
    }
}
